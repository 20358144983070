<template>
    <div class="pa-2">

      <v-row>
        <v-col cols="12">
          <v-btn @click="saveBd()">Guardar</v-btn>
          <v-btn @click="backup()">backup</v-btn>
        </v-col>
        <v-col cols="2">
          <table class="zebra-table">
            <tr><th>Name</th><th>Count</th></tr>
            <tbody>
            <tr v-for="tb in tables" :key="tb.name"><td><a @click="viewTable(tb.name)">{{ tb.name }} </a></td><td>{{ tb.count }}</td></tr>
          </tbody>
          </table>
        </v-col>
        <v-col cols="10" v-if="currentTable">

        <h4>Table: {{ currentTable.name }}</h4>
        <div v-if="currentTable.data && currentTable.data.length  > 0">
          <div class="table-container">
          <table class="zebra-table">
            <thead>
              <tr>
                <th v-for="h in getHeaders(currentTable.data[0])" :key="h">{{ h }}
                <input  v-model="filters[h]" @keyup="filterData(h)" />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr  v-for="(row, index) in filteredData" :key="index"  @click="openModal(row)">
                <td v-for="td in row" class="td-content">

                  <div class="cell-content">{{td }}</div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>

        </v-col>
      </v-row>
      <!--table>
        <thead>
          <tr>
            <th v-for="(header, index) in tableHeaders" :key="index">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
            <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
          </tr>
        </tbody>
      </table-->

      <v-dialog v-model="showModal" >
    <div style="background-color: white;" class="pa-4">
      <div >      
      <v-row v-for="(ct, index) in getModelData(modalContent)" :key="index">
        <template v-if="ct.key != 'meta'">
        <v-col cols="2"> <strong>{{ ct.key }}</strong></v-col>
        <v-col cols="10">{{ ct.value }}</v-col>
      </template>
      </v-row>
    </div>
    </div>
      </v-dialog>

    </div>
  </template>
  
  <script>

import localAgent from '@/plugins/local-agent.js'

  export default {
    data: () => ({
      tables: [],
      currentTable: null,
      showModal: false,
      modalContent: {},
      filters: {}
  }),

  mounted() {
    setTimeout(async () => {
      this.tables = await this.$db.getCollections();
    }, 500)
    
  },
  computed: {
    filteredData() {
      return this.currentTable.data.filter((row) => {
        let filters = Object.keys( this.filters)
        return filters.every((filter, index) => {
          const cellValue = row[filter]+'';
          return cellValue.includes(this.filters[filter]);
        });
        return true
      });
    },
  },
  methods: {
    async saveBd(){
      
      let txt = this.$db.serialize();
    
      let ok =  await localAgent.save(txt)
      if(!ok){
        console.log('AGENTE NO DISPONIBLE')
      }
    },
    async backup(){
      let txt = this.$db.serialize();
      let ok =  await localAgent.backup(txt)
      if(!ok){
        console.log('AGENTE NO DISPONIBLE')
      }
  
    },
    filterData(value) {
      //return (value) => {
       // this.filters[columnIndex] = value;
      //};
    },
    getModelData(data){
      let content = []

      for(let i in data){
        content.push({ key: i, value: data[i]})
      }
      return content;
    },
    openModal(content) {
      this.modalContent = content;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    viewTable(name){
      this.currentTable =  this.$db.getCollection(name);
    },
    getHeaders(data){
      return Object.keys(data);
    }
  }
  };
  </script>
  <<style scoped>

.table-container {
  max-height: 600px; /* Altura máxima de la tabla */
  overflow-y: auto; /* Scroll vertical si la tabla desborda */
  overflow-x: auto; /* Scroll horizontal si la tabla desborda */
}
.td-content{
  max-width: 120px;
}

  .zebra-table {
  width: 100%;
  border-collapse: collapse;
}

.zebra-table th, .zebra-table td {
  padding: 2px;
  border: 1px solid #ccc;
  text-align: left;
}

/* Estilo para las filas pares */
.zebra-table tbody tr:nth-child(even) {
  background-color: #cce6f0;
}

.cell-content {
  white-space: nowrap; /* Evita el salto de línea en el contenido largo */
  overflow: hidden;
  text-overflow: ellipsis; /* Agrega puntos suspensivos para contenido largo */
  cursor: pointer; /* Cambia el cursor al hacer clic en el contenido largo */
}

/* Estilo para la modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}
input{
  border: 1px solid #ccc;
  width: 100%;
}
  </style>